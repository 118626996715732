
.grid-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 30px;
  grid-template-columns: repeat(4, auto);
  border-collapse: collapse;
  > div{
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 15px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
    img{
      margin-bottom: 10px;
      max-width: 100px;
      height: auto;
    }
    span.site-label{
      margin-top: 10px;
      font-weight: bold;
      font-size: 20px;
    }
  }
}
@media screen and (max-width:767px) {
  .grid-container {
    grid-template-columns: repeat(2, auto);
    display: grid;
  }
}
